// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Role from "../../../../models/Role.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Country from "../../../../models/Country.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Location from "../../../../api/locations/Api_Location.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as LocationsIndexCss from "../../common/index/styles/LocationsIndexCss.res.js";
import * as LocationsIndexMap from "../../common/index/map/LocationsIndexMap.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexHeader from "../../common/index/header/LocationsIndexHeader.res.js";
import * as LocationsIndexContent from "../../common/index/content/LocationsIndexContent.res.js";
import * as LocationsIndexFilters from "../../common/index/filters/LocationsIndexFilters.res.js";
import * as LocationsIndexSubheader from "../../common/index/subheader/LocationsIndexSubheader.res.js";
import * as LocationsIndexTopStates from "../../common/index/top-states/LocationsIndexTopStates.res.js";
import * as LocationsIndexFetchResults from "../../common/index/fetch-result/LocationsIndexFetchResults.res.js";
import * as LocationsIndexTopProvidersNew from "../../common/index/top-providers/LocationsIndexTopProvidersNew.res.js";
import * as LocationsIndexBookACallSection from "../../common/index/book-a-call/LocationsIndexBookACallSection.res.js";
import * as LocationsIndexPreloadedSearchLocations from "../../common/index/preloaded-search-locations/LocationsIndexPreloadedSearchLocations.res.js";

function CountryShow$default(props) {
  var country = props.country;
  var initialState = React.useMemo((function () {
          return {
                  status: {
                    TAG: "Ready",
                    _0: country.locations
                  },
                  mapStatus: "FetchingMapLocations",
                  currentPage: 1,
                  totalPages: 1,
                  sortBy: {
                    NAME: "Popular",
                    VAL: "Desc"
                  },
                  search: "",
                  view: "Grid",
                  totalLocalities: 0,
                  totalLocations: country.locations.totalPreloadedSearchLocations,
                  totalProviders: country.locations.totalProviders,
                  currentTotalLocations: country.locations.totalPreloadedSearchLocations,
                  providerIds: [],
                  userRole: Role.fromString(country.locations.userRole),
                  resetDisabled: true,
                  filtersOpen: true,
                  resetCounter: 0,
                  cityId: undefined,
                  radius: 100
                };
        }), []);
  var container = React.useRef(null);
  var searchLocations = ReactDebounce.useDebounced(600, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var scrollToTop = function () {
    Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
            prim.scrollIntoView();
          }));
  };
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchLocations" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              $$Promise.wait(Api_Location.Country.show(country.id, state.currentPage, state.sortBy, state.search, state.providerIds, state.cityId, state.radius), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedLocationsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "CountryShow",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "CountryShow.default"
                                          }, "CountryShow::FetchLocations::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailLocationsFetch");
                                    }));
                            })
                        };
              case "FailLocationsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedLocationsFetch",
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: {
                              NAME: "Popular",
                              VAL: "Desc"
                            },
                            search: "",
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: [],
                            userRole: state.userRole,
                            resetDisabled: true,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter + 1 | 0,
                            cityId: state.cityId,
                            radius: state.radius
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var match = state.view;
                              switch (match) {
                                case "Grid" :
                                case "List" :
                                    return dispatch("FetchLocations");
                                case "Map" :
                                    return dispatch("FetchMapLocations");
                                
                              }
                            })
                        };
              case "FetchMapLocations" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              $$Promise.wait(Api_Location.Country.map(country.id, state.search, state.providerIds, state.cityId, state.radius), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedMapLocationsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "CountryShow",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "CountryShow.default"
                                          }, "CountryShow::FetchMapLocations::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailMapLocationsFetch");
                                    }));
                            })
                        };
              case "FailMapLocationsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            mapStatus: "FailedMapLocationsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          }
                        };
              case "EnableReset" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: false,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          }
                        };
              case "ToggleFilters" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: !state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedLocationsFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.locations
                            },
                            mapStatus: state.mapStatus,
                            currentPage: res.locations.currentPage,
                            totalPages: res.locations.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: res.locations.totalPreloadedSearchLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          }
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  scrollToTop();
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              mapStatus: state.mapStatus,
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              view: state.view,
                              totalLocalities: state.totalLocalities,
                              totalLocations: state.totalLocations,
                              totalProviders: state.totalProviders,
                              currentTotalLocations: state.currentTotalLocations,
                              providerIds: state.providerIds,
                              userRole: state.userRole,
                              resetDisabled: state.resetDisabled,
                              filtersOpen: state.filtersOpen,
                              resetCounter: state.resetCounter,
                              cityId: state.cityId,
                              radius: state.radius
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  scrollToTop();
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLocations",
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: action._0,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: false,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: false,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          },
                          _1: searchLocations
                        };
              case "PerformSearch" :
                  var input = action._0;
                  var match$2 = state.view;
                  var exit = 0;
                  switch (match$2) {
                    case "Grid" :
                    case "List" :
                        exit = 1;
                        break;
                    case "Map" :
                        var match$3 = state.mapStatus;
                        var exit$1 = 0;
                        if (typeof match$3 !== "object") {
                          if (match$3 === "FetchingMapLocations") {
                            return "NoUpdate";
                          }
                          exit$1 = 2;
                        } else {
                          exit$1 = 2;
                        }
                        if (exit$1 === 2) {
                          if (input !== state.search) {
                            return "NoUpdate";
                          } else {
                            return {
                                    TAG: "UpdateWithSideEffects",
                                    _0: {
                                      status: state.status,
                                      mapStatus: "FetchingMapLocations",
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      sortBy: state.sortBy,
                                      search: state.search,
                                      view: state.view,
                                      totalLocalities: state.totalLocalities,
                                      totalLocations: state.totalLocations,
                                      totalProviders: state.totalProviders,
                                      currentTotalLocations: state.currentTotalLocations,
                                      providerIds: state.providerIds,
                                      userRole: state.userRole,
                                      resetDisabled: state.resetDisabled,
                                      filtersOpen: state.filtersOpen,
                                      resetCounter: state.resetCounter,
                                      cityId: state.cityId,
                                      radius: state.radius
                                    },
                                    _1: (function (param) {
                                        param.dispatch("FetchMapLocations");
                                      })
                                  };
                          }
                        }
                        break;
                    
                  }
                  if (exit === 1) {
                    var match$4 = state.status;
                    var exit$2 = 0;
                    if (typeof match$4 !== "object") {
                      if (match$4 === "FetchingLocations") {
                        return "NoUpdate";
                      }
                      exit$2 = 2;
                    } else {
                      exit$2 = 2;
                    }
                    if (exit$2 === 2) {
                      if (input !== state.search) {
                        return "NoUpdate";
                      } else {
                        return {
                                TAG: "UpdateWithSideEffects",
                                _0: {
                                  status: "FetchingLocations",
                                  mapStatus: state.mapStatus,
                                  currentPage: state.currentPage,
                                  totalPages: state.totalPages,
                                  sortBy: state.sortBy,
                                  search: state.search,
                                  view: state.view,
                                  totalLocalities: state.totalLocalities,
                                  totalLocations: state.totalLocations,
                                  totalProviders: state.totalProviders,
                                  currentTotalLocations: state.currentTotalLocations,
                                  providerIds: state.providerIds,
                                  userRole: state.userRole,
                                  resetDisabled: state.resetDisabled,
                                  filtersOpen: state.filtersOpen,
                                  resetCounter: state.resetCounter,
                                  cityId: state.cityId,
                                  radius: state.radius
                                },
                                _1: (function (param) {
                                    param.dispatch("FetchLocations");
                                  })
                              };
                      }
                    }
                    
                  }
                  break;
              case "ToggleView" :
                  var view = action._0;
                  switch (view) {
                    case "Grid" :
                    case "List" :
                        break;
                    case "Map" :
                        return {
                                TAG: "UpdateWithSideEffects",
                                _0: {
                                  status: state.status,
                                  mapStatus: state.mapStatus,
                                  currentPage: state.currentPage,
                                  totalPages: state.totalPages,
                                  sortBy: state.sortBy,
                                  search: state.search,
                                  view: view,
                                  totalLocalities: state.totalLocalities,
                                  totalLocations: state.totalLocations,
                                  totalProviders: state.totalProviders,
                                  currentTotalLocations: state.currentTotalLocations,
                                  providerIds: state.providerIds,
                                  userRole: state.userRole,
                                  resetDisabled: state.resetDisabled,
                                  filtersOpen: state.filtersOpen,
                                  resetCounter: state.resetCounter,
                                  cityId: state.cityId,
                                  radius: state.radius
                                },
                                _1: (function (param) {
                                    param.dispatch("FetchMapLocations");
                                  })
                              };
                    
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "UpdateTotalProviders" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: action._0,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          }
                        };
              case "UpdateTotalLocalities" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: action._0,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          }
                        };
              case "UpdateProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: action._0,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var match = state.view;
                              switch (match) {
                                case "Grid" :
                                case "List" :
                                    return dispatch("FetchLocations");
                                case "Map" :
                                    return dispatch("FetchMapLocations");
                                
                              }
                            })
                        };
              case "UpdateCityRadius" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            mapStatus: state.mapStatus,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: action._0,
                            radius: action._1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var match = state.view;
                              switch (match) {
                                case "Grid" :
                                case "List" :
                                    return dispatch("FetchLocations");
                                case "Map" :
                                    return dispatch("FetchMapLocations");
                                
                              }
                            })
                        };
              case "SucceedMapLocationsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            mapStatus: {
                              TAG: "ReadyMap",
                              _0: action._0
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole,
                            resetDisabled: state.resetDisabled,
                            filtersOpen: state.filtersOpen,
                            resetCounter: state.resetCounter,
                            cityId: state.cityId,
                            radius: state.radius
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var res = state.status;
  var tmp;
  if (typeof res !== "object") {
    tmp = res === "FetchingLocations" ? JsxRuntime.jsx(LocationsIndexFetchResults.make, {
            status: "Fetching",
            reset: (function (param) {
                dispatch("Reset");
              })
          }) : JsxRuntime.jsx(LocationsIndexFetchResults.make, {
            status: "Error",
            reset: (function (param) {
                dispatch("Reset");
              })
          });
  } else {
    var res$1 = res._0;
    var match$1 = state.view;
    switch (match$1) {
      case "Grid" :
          var match$2 = res$1.totalPreloadedSearchLocations;
          tmp = match$2 !== 0 ? JsxRuntime.jsx(LocationsIndexPreloadedSearchLocations.make, {
                  index: res$1,
                  updatePage: (function (pageNum) {
                      dispatch({
                            TAG: "UpdatePage",
                            _0: pageNum
                          });
                    })
                }) : JsxRuntime.jsx(LocationsIndexFetchResults.make, {
                  status: "Empty",
                  reset: (function (param) {
                      dispatch("Reset");
                    })
                });
          break;
      case "List" :
          tmp = null;
          break;
      case "Map" :
          var mapRes = state.mapStatus;
          tmp = typeof mapRes !== "object" ? (
              mapRes === "FetchingMapLocations" ? JsxRuntime.jsx(LocationsIndexFetchResults.make, {
                      status: "Fetching",
                      reset: (function (param) {
                          dispatch("Reset");
                        })
                    }) : JsxRuntime.jsx(LocationsIndexFetchResults.make, {
                      status: "Error",
                      reset: (function (param) {
                          dispatch("Reset");
                        })
                    })
            ) : JsxRuntime.jsx(LocationsIndexMap.make, {
                  preloadedSearchLocations: mapRes._0.preloadedSearchLocations,
                  mapId: "CountryShow",
                  userRole: state.userRole,
                  latitude: country.latitude,
                  longitude: country.longitude,
                  defaultZoom: 4
                });
          break;
      
    }
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LocationsIndexFilters.make, {
                      filtersOpen: state.filtersOpen,
                      resetCounter: state.resetCounter,
                      enableReset: (function () {
                          dispatch("EnableReset");
                        }),
                      updateProviderIds: (function (providerIds) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateProviderIds",
                                  _0: providerIds
                                });
                          };
                        }),
                      updateCityRadius: (function (cityId, radius) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateCityRadius",
                                  _0: cityId,
                                  _1: radius
                                });
                          };
                        }),
                      countryId: Caml_option.some(country.id),
                      onClick: (function (param) {
                          dispatch("ToggleFilters");
                        })
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(LocationsIndexHeader.make, {
                              locationsCount: state.currentTotalLocations,
                              resetDisabled: state.resetDisabled,
                              breadcrumbs: Country.Show.breadcrumbs(country),
                              title: country.name + " Data Centers Locations",
                              search: state.search,
                              reset: (function (param) {
                                  dispatch("Reset");
                                }),
                              onChange: (function ($$event) {
                                  dispatch({
                                        TAG: "UpdateSearchInput",
                                        _0: $$event.target.value
                                      });
                                })
                            }),
                        JsxRuntime.jsx(LocationsIndexSubheader.make, {
                              view: state.view,
                              updateView: (function (view) {
                                  dispatch({
                                        TAG: "ToggleView",
                                        _0: view
                                      });
                                }),
                              sortBy: state.sortBy,
                              sort: (function (sort) {
                                  return function (param) {
                                    dispatch({
                                          TAG: "SortBy",
                                          _0: sort
                                        });
                                  };
                                }),
                              indexType: "Country"
                            }),
                        tmp,
                        JsxRuntime.jsx(LocationsIndexContent.make, {
                              market: country.name,
                              totalLocations: country.locations.totalPreloadedSearchLocations,
                              totalProviders: country.locations.totalProviders,
                              totalCountries: country.locations.totalCountries,
                              totalCities: country.locations.totalCities,
                              topProviders: Belt_Array.slice(country.locations.providers, 0, 10),
                              content: country.description
                            }),
                        JsxRuntime.jsx(LocationsIndexTopStates.make, {
                              states: country.locations.states,
                              administrativeDivisionPlural: country.administrativeDivisionPlural
                            }),
                        JsxRuntime.jsx(LocationsIndexTopProvidersNew.make, {
                              providers: country.locations.providers
                            }),
                        JsxRuntime.jsx(LocationsIndexBookACallSection.make, {})
                      ],
                      ref: Caml_option.some(container),
                      className: LocationsIndexCss.Locations.container
                    })
              ],
              ref: Caml_option.some(container),
              className: LocationsIndexCss.container
            });
}

var Css;

var $$default = CountryShow$default;

export {
  Css ,
  $$default as default,
}
/* Role Not a pure module */
